// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prd.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // Configuration Cognito Open id accessible via l'url : https://cognito-idp.<region>.amazonaws.com/<poolId>/.well-known/openid-configuration
    // Par exemple : https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_bt23vOhrX/.well-known/openid-configuration
    clientId: "bn18t1sr7q1p5es5im2682tq3",
    loginUrl: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/oauth2/authorize',
    issuer: 'https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_bt23vOhrX',
    logoutUrl: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/logout',
    tokenEndpoint: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/oauth2/token',
    userinfoEndpoint: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/oauth2/userInfo',
    jwks: {
        "keys": [
            {
                "alg": "RS256",
                "e": "AQAB",
                "kid": "Ct4WmgsLXN8U9BahXbjrPLJ2prb/t1cjf3JfyfQOU9E=",
                "kty": "RSA",
                "n": "uLO2QX5R0T1cU5M5vfEdStPwrXhja1rUqRZh1xgkgYyx7V4uOKeNyeOAtld-FaQPFaHwB1ffqGWwZqMBOKT5w24blDipzz4zG5tCa-ZTJvl867ADbsZjvnJzDJwO8KIvjShYyoxfaftxd1e3ZI1V1wJqPOYi4Vavo55QbY4jm5PvsyCVjYLd9oqXsHjz62k8Yl8nL9OeH65zXaYPZ2J2u774JnRSJ-St9qb11F0oi54F6XqHhKg0HiQ-f6L8m8j9KZyz3b4qmQjH1Z-vvO9DCEkoVVcRq7BpPjKl44e03Au2G4JhKEisq0BCVqi_cOJ-DsK_9KQj2tHokVbH3BgvOw",
                "use": "sig"
            },
            {
                "alg": "RS256",
                "e": "AQAB",
                "kid": "OcpNeH9tIB9v/yUeEgJyFmLGze+YRAL9FrXX9WbMmAs=",
                "kty": "RSA",
                "n": "wtjSbS8ICLFyktrnkxY0e-jWyDINYmsLaXr8sk9R0bQEN82469fje3Gc3MinqVJopgVJhns2JzIrUO16GDa4wIGLipKKy1Mnx0j2MDiYF1iEudKARHOHyDuLhYpn10k3lNBVC26WjVypA3ncPcdGkTXG6G8K7j9IABudKjTZUkoLxjSJlh5l7yvKvFwGLl25p4darjf4oV2qZlic5eZ2gzUryTRoZSRj8bDP_eEa7pBMRJ-yjAkBV_H3um76hXtkkIMX4aa0TQAdhx-lji36_97wpzqHQxvX0J3wCXcmNo8UNs0T1hyydOjrKU-aLygAM9ZASEJkfO0Qd2lcOJyqAw",
                "use": "sig"
            }
        ]
    },
    baseUrl: "https://automat-dev.leon-grosse.fr/aautocontrole-materiel-api-rest",
    plansDriveBaseUrl: "https://drive.google.com/uc?id=:idDrive",
    controleByMaterielIdListApi: "/materielSecurites/controles/:idMateriel",
    typeRegroupementAffaireListApi: "/data-rest/typeControleAffaires/:idTypeControleAffaire/typeRegroupementAffaires",
    pointDeControleListApi: "/controles/:idControle/typeRegroupements/:idTypeRegroupementAffaire/pointDeControles",
    controleApi: "/controles",
    materielNotificationListApi: "/materielSecurites/notifications/:idMateriel",
    familleMaterielTypeControleApi: "/familleMateriels/:idFamille/typeControles",
    familleMaterielApi: "/familleMateriels",
    typeControleApi: "/typeControles",
    typeRegroupementApi: "/typeRegroupements",
    typePointDeControleApi: "/typePointDeControles",
    filiereApi: "/filieres",
    utilisateurApi: "/utilisateurs",
    pointDeControleApi: "/pointDeControles",
    photoApi: "/photos",
    affaireApi: "/affaires",
    planApi: "/plans/:maquetteNumeriqueCode",
    materielSecuriteApi: "/materielSecurites"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
